<template>
  <div style="width: 1200px; margin: 0 auto">
    <div class="titlename flexcont">
      {{ this.$route.params.type == 2 ? "商家资讯" : "会员资讯" }}
    </div>
    <list :list="datalist" />
    <!-- 分页 -->
    <div class="pagination" v-if="total > 10">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pagesize"
        layout="prev, pager, next,total, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import list from "@/components/dotai/datalist";
export default {
  name: "datadetails",
  components: { list },
  data() {
    return {
      pageNum: 1, // 当前页
      datalist: [],
      type: "",
      total: 0, // 总条数
      pagesize: 10, // 每页条数
      e:null, // 类型
    };
  },
  created() {
    // 类型，当前页，每条页数
    this.e = this.$route.params.type
    this.uplist(this.e,this.pageNum,this.pagesize);
  },
  watch: {},
  methods: {
    uplist(e,pageNum,pagesize) {
      this.api
        .news({
          pageNum: pageNum,
          pageSize: pagesize,
          type: e,
        })
        .then((res) => {
          this.datalist = res.data.rows;
          this.total = res.data.total;
          // console.log(res);
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      // this.getzhaopin
      this.uplist(this.e,this.pageNum, this.pagesize);
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.titlename {
  margin: 53px 0;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #981c1c;
}
.pagination {
  text-align: center;
  margin: 20px 0;
}
</style>

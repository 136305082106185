<template>
  <div>
    <div
      class="databody wow lightSpeedIn"
      @click="getdetails(i.id)"
      v-for="(i, k) in list"
      :key="k"
    >
      <h4 style="color: #981c1c">{{ i.newsTitle }}</h4>
      <div class="shenglue2">
        {{ i.newsContent }}
      </div>
      <div class="tiem">{{ i.createTime }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  props: ['list'],
  data() {
    return {

    }
  },
  created() { },
  watch: {},
  methods: {
    // 详情跳转
    getdetails(e) {
      this.$router.push({ path: `/dynamicDetails/${e}` })
    }
  },
  computed: {},
}
</script>
<style lang="scss" scoped>
.databody {
  cursor: pointer;
  margin-bottom: 20px;
  width: 1200px;
  height: 152px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  padding: 21px 337px 20px 36px;
  .shenglue2 {
    margin-top: 14px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
  .tiem {
    margin-top: 9px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
}
</style>
